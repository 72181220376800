.permissions-table-wrapper {
  overflow-x: scroll;
  max-width: 100%;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:horizontal {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
}

.permissions-table {
  font-size: .9rem;
  min-width: 100%;
  table-layout: fixed;

  th,
  td {
    width: 9rem;
    min-width: 9rem;
  }
}
