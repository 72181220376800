@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700&display=fallback');

$fa-font-path: "~@fortawesome/fontawesome-free/webfonts" !default;

@import 'adminlte';
@import 'custom';
@import 'permissions';
@import 'translations';
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";

.login-box, .register-box {
  width: auto;
}

::-ms-reveal {
  display: none;
}
